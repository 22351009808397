<template>
  <div id="app">
    <link rel="preload" href="/img/earth.png" as="image" />
    <link rel="preload" href="/img/yoyoDown.gif" as="image" />
    <link rel="preload" href="https://d1j8pt39hxlh3d.cloudfront.net/uploads/face_throwing_a_kiss_256_1.gif" as="image"/>
    <link rel="preload" href="https://d1j8pt39hxlh3d.cloudfront.net/uploads/loudly_crying_face_256_1.gif" as="image"/>
    <link rel="preload" href="https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif" as="image"/>
    <link rel="preload" href="/img/blank.png" as="image" />
    <link rel="preload" href="/img/duoduo-baby.png" as="image" />
    <link rel="preload" href="/img/flying-stars.png" as="image" />
    <link rel="preload" href="/img/stars.png" as="image" />
    <link rel="preload" href="/img/yoyo-baby.png" as="image" />
    <link rel="preload" href="/img/yoyo-duoduo-away.png" as="image" />
    <link rel="preload" href="/img/yoyo-duoduo-together.png" as="image" />
    <link rel="preload" href="/img/yoyoHappy.gif" as="image" />
    <link rel="preload" href="/img/yoyoHello.gif" as="image" />
    <link rel="preload" href="/img/yoyoShow.gif" as="image" />
    <link rel="preload" href="/img/yoyoRead.gif" as="image" />
    <link rel="preload" href="/particle/starsMove.json" as="fetch"/>
    <link rel="preload" href="/particle/particlesjs-config.json" as="fetch"/>
    
    <audio
      ref="audio"
      src="/audio/blossom.mp3"
      loop
      id="audio"
      autoplay
    ></audio>
    <router-view />
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      backgroundColor: "black",
    };
  },
};
</script>

<style>
body {
  overflow: hidden;
}

.blackBG {
  background-color: black;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
