var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.step1)?_c('div',{staticStyle:{"z-index":"1000"}},[_c('vue-typed-js',{attrs:{"strings":[
            "<p>Good !</p>^500",
            "<p>Yoyo was waiting for you.</p>^500",
            "<p>I let you see him now !</p>^500",
            '' ],"contentType":'html'},on:{"onComplete":function($event){return _vm.endStep1()}}},[_c('div',{staticClass:"typing",staticStyle:{"color":"white","z-index":"1100"}})])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticStyle:{"position":"absolute","pointer-events":"none"},attrs:{"id":"step-3"}},[(_vm.displayHeart)?_c('svg',{class:{ giant: _vm.fadeHeart },staticStyle:{"enable-background":"new 0 0 100 85"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","width":"30px","height":"30px","viewBox":"0 0 100 85","xml:space":"preserve"}},[_c('defs'),_c('path',{staticClass:"st0",attrs:{"d":"M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0\n\tc-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"}})]):_vm._e(),(_vm.step2)?_c('div',{staticStyle:{"pointer-events":"none"}},[_c('div',{attrs:{"id":"heartSpawner"}},_vm._l((_vm.heartList),function(value,key){return _c('div',{key:key,staticClass:"heart",style:({
                left: ((_vm.heartPos.x) + "%"),
                top: ((_vm.heartPos.y) + "%"),
              })},[_vm._v(" ❤ ")])}),0),(_vm.haveToKiss)?_c('div',{staticClass:"have-to-kiss",style:({
              left: ((_vm.toKissPos.x) + "%"),
              top: ((_vm.toKissPos.y) + "%"),
            }),on:{"click":_vm.kissed}}):_vm._e(),(_vm.vitaminsSequence)?_c('div',{staticClass:"have-to-kiss",style:({
              left: ((_vm.toKissPos.x) + "%"),
              top: ((_vm.toKissPos.y) + "%"),
            }),on:{"click":_vm.kissedVitamins}}):_vm._e(),(_vm.yoyoImg)?_c('img',{staticStyle:{"z-index":"999","width":"400px"},attrs:{"draggable":"false","src":_vm.yoyoSrc}}):_vm._e(),_c('div',{class:{
                yoyoTalk: !_vm.noBorderYoyoTalk,
                yoyoTalkNoBorder: _vm.noBorderYoyoTalk,
              },on:{"click":_vm.speak}},[_c('p',{staticClass:"yoyoTalkText",class:{
                yoyoTalkText: !_vm.noBorderYoyoTalk,
                yoyoTalkTextNoBorder: _vm.noBorderYoyoTalk,
              },style:({
                fontSize: _vm.dialongFontSize + 'px',
                color: _vm.dialogColor,
              })},[_vm._v(" "+_vm._s(_vm.dialog[_vm.dialogStep].text)+" ")])]),_c('transition',{attrs:{"name":"fadeGrow"}},[(_vm.vitaminsSequence)?_c('p',{attrs:{"id":"vitaminsLabel"}},[_vm._v("Vitamins level :")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.vitaminsSequence)?_c('div',{attrs:{"id":"vitaminsBar"}},[_c('div',{attrs:{"id":"emptyBar"}}),_c('div',{style:({
                  width: ((this.vitaminsLevel) + "%"),
                }),attrs:{"id":"fullBar"}})]):_vm._e()])],1):_vm._e()])]),(_vm.spaceBackground)?_c('Particles',{staticStyle:{"z-index":"-1"},attrs:{"id":"tsparticles","url":"/particle/particlesjs-config.json"}}):_vm._e(),(_vm.starsTravel)?_c('Particles',{staticStyle:{"z-index":"-1"},attrs:{"id":"tsparticles2","url":"/particle/starsMove.json"}}):_vm._e(),(_vm.starsTravel)?_c('div',{staticClass:"stars-traveling"},[_c('img',{staticClass:"stars-top",attrs:{"draggable":"false","src":"/img/stars.png"}}),_c('img',{staticClass:"stars-bottom",attrs:{"draggable":"false","src":"/img/stars.png"}})]):_vm._e(),(_vm.starsLookEarth)?_c('div',{staticClass:"stars-look-hearth"},[(_vm.starsLeftTop)?_c('img',{staticClass:"stars-top-left",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/stars.png"}}):_vm._e(),(_vm.starsLeftBottom)?_c('img',{staticClass:"stars-bottom-left",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/stars.png"}}):_vm._e(),_c('img',{staticClass:"earth-right",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/earth.png"}}),(_vm.flyingStar)?_c('img',{staticClass:"flying-star",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/flying-stars.png"}}):_vm._e(),(_vm.duoduoBaby)?_c('img',{staticClass:"earth-right",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/duoduo-baby.png"}}):_vm._e(),(_vm.yoyoBaby)?_c('img',{staticClass:"earth-right",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/yoyo-baby.png"}}):_vm._e(),(_vm.yoyoDuoduoAway)?_c('img',{staticClass:"earth-right",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/yoyo-duoduo-away.png"}}):_vm._e(),(_vm.yoyoDuoduoTogether)?_c('img',{staticClass:"earth-right",staticStyle:{"pointer-events":"none"},attrs:{"draggable":"false","src":"/img/yoyo-duoduo-together.png"}}):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }