<template>
  <div id="step-2">
    <transition name="fade">
      <div v-if="step1">
        <vue-typed-js
          @onComplete="endStep1()"
          :strings="[
            `<p>${name.capitalize()} ^500 that's a lovely name </p>`,
            `<p>^500 You are Yoyo's 老婆 right ?</p>`,
          ]"
          :contentType="'html'"
        >
          <div v-bind:class="{ goUp: isGoUp }" class="typing"></div>
        </vue-typed-js>
      </div>
    </transition>

    <transition name="fadeGrow">
      <div style="height: 400px" v-if="step2">
        <div>
          <button
            v-on:mouseenter="mouseEnterYes"
            v-on:mouseleave="mouseLeaveYes"
            type="button"
            class="yes-no-btn btn btn-primary"
            v-on:click="endStep2"
          >
            Yes
            <svg
              v-bind:class="{ giant: isHeartGrowing }"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="30px"
              height="30px"
              viewBox="0 0 100 85"
              style="enable-background: new 0 0 100 85"
              xml:space="preserve"
              
            >
              <defs></defs>
              <path
                class="st0"
                d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0
	c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"
              />
            </svg>
          </button>
          <button
            v-on:mouseenter="mouseEnterNo"
            v-on:mouseleave="mouseLeaveNo"
            type="button"
            class="yes-no-btn btn btn-primary"
            v-on:click="intrusion()"
          >
            No
          </button>
        </div>
        <div>
          <img class="smiley-image" v-bind:src="smileySource" />
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@keyframes giant {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(250);

  }
}

.giant {
  animation-name: giant;
  animation-duration: 1s;
}

svg {
  z-index: 1000 !important;
}

.st0 {
  fill: #920d0d;
}

.smiley-image {
  width: 200px;
  height: 200px;
}
.yes-no-btn {
  width: 80px;
  height: 80px;
  margin: 30px;
}

#step-2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes go-up-200px {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-200px);
  }
}

.goUp {
  animation-name: go-up-200px;
  animation-duration: 1s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  transition: opacity 0s;
  opacity: 0; /* Equal to scaleX(0.7) scaleY(0.7) */
}

.fadeGrow-enter-active,
.fadeGrow-leave-active {
  transition: transform 0.5s;
}
.fadeGrow-enter,
.fadeGrow-leave-to {
  opacity: 0;
  transform: scale(0);
}

.fadeGrowBottom-enter-active,
.fadeGrowBottom-leave-active {
  transition: transform 0.5s;
}
.fadeGrowBottom-enter,
.fadeGrowBottom-leave-to {
  transform: scaleY(0);
}
</style>

<script>
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
export default {
  name: "Step2",
  data() {
    return {
      smileySource:
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif",
      name: "",
      step1: true,
      step2: false,
      isGoUp: false,
      isHeartGrowing: false,
    };
  },
  created() {
    this.name = this.$route.params.name;
  },
  methods: {
    mouseEnterYes() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/face_throwing_a_kiss_256_1.gif";
    },
    mouseEnterNo() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/loudly_crying_face_256_1.gif";
    },
    mouseLeaveYes() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif";
    },
    mouseLeaveNo() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif";
    },
    intrusion() {
      this.$router.push("/dani/" + this.name);
    },
    endStep1() {
      this.isGoUp = true;
      let data = this.$data;
      setTimeout(function () {
        data.isGoUp = false;

        data.step2 = true;
      }, 1000);
    },
    endStep2() {
      this.isHeartGrowing = true;
      let router = this.$router;
      setTimeout(() => {
        router.push("/3/" + this.name);
      },1000);
    },
  },
};
</script>