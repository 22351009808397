<template>
  <div id="step-1">
    <transition name="fade">
      <div v-bind:class="{ goUp: isGoUp }" v-if="step1">
        <vue-typed-js
          @onComplete="endStep1()"
          :strings="['<p>Hello !^500 Who are you ?<p>^100']"
          :contentType="'html'"
        >
          <div style="height: 100px" class="typing"></div>
        </vue-typed-js>
      </div>
    </transition>
    <transition name="fadeGrowBottom">
      <div v-if="step2" style="height: 200px">
        <b-form-input
          style="width: 200px; margin: 0px auto"
          v-model="name"
          placeholder="Type name..."
        ></b-form-input>

        <button
          style="width: 200px; margin: 0px auto; margin-top: 20px"
          type="button"
          class="btn btn-primary"
          v-on:click="endStep2"
        >
          Say name
        </button>
      </div>
    </transition>
  </div>
</template>
<style >
.typing {
  width: 100%;
}
.typing > p {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  font-size: 50px;
  text-align: center;
}
</style>

<style scoped>
@keyframes go-up-75px {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
}

.goUp {
  animation-name: go-up-75px;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.smiley-image {
  width: 200px;
  height: 200px;
}
.yes-no-btn {
  width: 80px;
  height: 80px;
  margin: 30px;
}

#step-1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  transition: opacity 0s;
  opacity: 0; /* Equal to scaleX(0.7) scaleY(0.7) */
}

.fadeGrow-enter-active,
.fadeGrow-leave-active {
  transition: transform 0.5s;
}

.fadeGrow-enter,
.fadeGrow-leave-to {
  opacity: 0;
  transform: scale(0);
}

.fadeGrowBottom-enter-active,
.fadeGrowBottom-leave-active {
  transition: transform 0.5s;
}

.fadeGrowBottom-enter,
.fadeGrowBottom-leave-to {
  transform: scaleY(0);
}
</style>

<script>
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default {
  name: "Step1",
  data() {
    return {
      smileySource:
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif",
      name: "",
      step1: true,
      step2: false,
      isGoUp: false,
    };
  },
  methods: {
    mouseEnterYes() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/face_throwing_a_kiss_256_1.gif";
    },
    mouseEnterNo() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/loudly_crying_face_256_1.gif";
    },
    mouseLeaveYes() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif";
    },
    mouseLeaveNo() {
      this.smileySource =
        "https://d1j8pt39hxlh3d.cloudfront.net/uploads/zipper_mouth_face_256_1.gif";
    },


    clickYes() {},
    clickNo() {},
    endStep1() {
      this.isGoUp = true;
      let data = this.$data;

      setTimeout(() => {
        data.isGoUp = false;
        data.step2 = true;
      }, 1000);
    },
    endStep2() {
      this.$router.push("/2/" + this.name);
    },
  },
};
</script>