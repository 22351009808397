
<template>
  <div>
    <transition name="fade">
      <div style="z-index: 1000" v-if="step1">
        <vue-typed-js
          @onComplete="endStep1()"
          :strings="[
            `<p>Good !</p>^500`,
            `<p>Yoyo was waiting for you.</p>^500`,
            `<p>I let you see him now !</p>^500`,
            '',
          ]"
          :contentType="'html'"
        >
          <div style="color: white; z-index: 1100" class="typing"></div>
        </vue-typed-js>
      </div>
    </transition>

    <transition name="fade">
      <div id="step-3" style="position: absolute; pointer-events: none;">
        <svg
          v-if="displayHeart"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="30px"
          height="30px"
          viewBox="0 0 100 85"
          style="enable-background: new 0 0 100 85"
          xml:space="preserve"
          v-bind:class="{ giant: fadeHeart }"
        >
          <defs></defs>
          <path
            class="st0"
            d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0
	c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"
          />
        </svg>
        <div style="pointer-events: none;" v-if="step2">
          <div id="heartSpawner">
            <div
              v-bind:style="{
                left: `${heartPos.x}%`,
                top: `${heartPos.y}%`,
              }"
              v-bind:key="key"
              v-for="(value, key) in heartList"
              class="heart"
            >
              ❤
            </div>
          </div>

          <div
            v-on:click="kissed"
            v-if="haveToKiss"
            class="have-to-kiss"
            v-bind:style="{
              left: `${toKissPos.x}%`,
              top: `${toKissPos.y}%`,
            }"
          ></div>
          <div
            v-bind:style="{
              left: `${toKissPos.x}%`,
              top: `${toKissPos.y}%`,
            }"
            v-on:click="kissedVitamins"
            v-if="vitaminsSequence"
            class="have-to-kiss"
          ></div>

          <img
          draggable="false"
            v-if="yoyoImg"
            style="z-index: 999; width: 400px"
            v-bind:src="yoyoSrc"
          />

          <div v-on:click="speak" 
                        v-bind:class="{
                yoyoTalk: !noBorderYoyoTalk,
                yoyoTalkNoBorder: noBorderYoyoTalk,
              }">
            <p
              v-bind:style="{
                fontSize: dialongFontSize + 'px',
                color: dialogColor,
              }"
              v-bind:class="{
                yoyoTalkText: !noBorderYoyoTalk,
                yoyoTalkTextNoBorder: noBorderYoyoTalk,
              }"
              class="yoyoTalkText"
            >
              {{ dialog[dialogStep].text }}
            </p>
          </div>
          <transition name="fadeGrow">
            <p v-if="vitaminsSequence" id="vitaminsLabel">Vitamins level :</p>
          </transition>
          <transition name="fade">
            <div v-if="vitaminsSequence" id="vitaminsBar">
              <div id="emptyBar"></div>
              <div
                v-bind:style="{
                  width: `${this.vitaminsLevel}%`,
                }"
                id="fullBar"
              ></div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    <Particles
      v-if="spaceBackground"
      style="z-index: -1"
      id="tsparticles"
      url="/particle/particlesjs-config.json"
    />

    <Particles
      v-if="starsTravel"
      style="z-index: -1"
      id="tsparticles2"
      url="/particle/starsMove.json"
    />

    <div class="stars-traveling" v-if="starsTravel">
      <img draggable="false" class="stars-top" src="/img/stars.png" />
      <img draggable="false" class="stars-bottom" src="/img/stars.png" />
    </div>

    <div v-if="starsLookEarth" class="stars-look-hearth">
      <img style="pointer-events: none;" v-if="starsLeftTop" draggable="false" class="stars-top-left" src="/img/stars.png" />
      <img style="pointer-events: none;" v-if="starsLeftBottom" draggable="false" class="stars-bottom-left" src="/img/stars.png" />
      <img style="pointer-events: none;" draggable="false" class="earth-right" src="/img/earth.png" />
      <img style="pointer-events: none;" v-if="flyingStar" draggable="false" class="flying-star" src="/img/flying-stars.png" />
      <img style="pointer-events: none;" v-if="duoduoBaby" draggable="false" class="earth-right" src="/img/duoduo-baby.png" />
      <img style="pointer-events: none;" v-if="yoyoBaby" draggable="false" class="earth-right" src="/img/yoyo-baby.png" />
      <img style="pointer-events: none;" v-if="yoyoDuoduoAway" draggable="false" class="earth-right" src="/img/yoyo-duoduo-away.png" />
      <img style="pointer-events: none;" v-if="yoyoDuoduoTogether" draggable="false" class="earth-right" src="/img/yoyo-duoduo-together.png" />
    </div>
  </div>
</template>
<script>

export default {
  name: "Step2",
  data() {
    return {
      noBorderYoyoTalk: false,
      flyingStar: false,
      starsLeftTop: false,
      starsLeftBottom: false,
      duoduoBaby: false,
      yoyoBaby: false,
      yoyoDuoduoAway: false,
      yoyoDuoduoTogether: false,

      starsLookEarth: false,
      starsTravel: false,
      yoyoImg: true,
      spaceBackground: false,
      heartId: 0,
      heartList: {},
      kissHeart: false,
      fadeHeart: false,
      step1: true, // true
      step2: false, // false
      displayHeart: true, // true
      yoyoSrc: "/img/yoyoShow.gif",
      dialog: [
        { text: "Click here to make me speak.", fontSize: 10 },
        { text: "老婆", src: "/img/yoyoHello.gif", fontSize: 30 },
        { text: "朵朵", fontSize: 30 },
        { text: "I was waiting for you.", fontSize: 20 },
        { text: "I missed you." },
        {
          text: "Can i have a kiss ?",
          pause: true,
          call: this.haveToKissStart,
        },
        { text: "Thank you 老婆." },
        { text: "I need you." },
        {
          text: "I need vitamins.",
          pause: true,
          call: this.vitaminsSequenceStart,
          src: "/img/yoyoDown.gif",
        },
        { text: "Bébé" },
        { text: "Thanks, i feel alive again !" },
        {
          text: "I wanted you to come here for something special !",
          src: "/img/yoyoHello.gif",
        },
        { text: "But for that i need to tell you a story." },
        { text: "You already know this story but today it's more special." },
        { text: "Let me turn off the light first." },
        { text: "", pause: true, call: this.lightOff, src: "/img/blank.png" },
        { text: "Wait", dialogColor: "white" },
        { text: "Let me find..." },
        { text: "OK", src: "/img/yoyoRead.gif" },
        { text: "This story begin a long time ago." },
        {
          text: "In a distant galaxy...",
          call: this.littleLightOn,
          src: "/img/blank.png",
        },
        {
          text: "Two stars we're travelling the universe",
          call: this.starsTravelOn,
        },
        { text: "These two stars we're deeply connected." },
        { text: "Loving each other." },
        {
          text: "For billions of years they we're spreading love and hapiness.",
        },
        {
          text: "One day, they passed by a little planet, called Earth.",
          call: this.starsLookEarthOn,
        },
        {
          text:
            "On this planet they saw this little creatures.",
        },
        { text: "The humans." },
        { text: "They observed this humans for a while." },
        { text: "They saw all the love they had." },
        { text: "And they became envious cause on earth they could hug and kiss." },
        { text: "While Yoyo and Duoduo couldn't hug and kiss each other." },
        { text: "They stayed here for a while, dreaming to become one of them." },
        { text: "After a while a flying stars passed by.", call: this.flyingStarOn },
        { text: "She told the lovers she could exhaust one of their dream." },
        { text: "So they both had the same idea." },
        { text: "To become humans and be able to hug and kiss." },
        { text: "On this words the flying stars exhausted their wish." },
        { text: "But not in the way they expected." },
        { text: "She first put Duoduo into China, in the body of a baby.", call: this.duoduoBabyGo },
        { text: "All her memory was removed, she forgot everything about Yoyo." },
        { text: "Then 5 years later she did the same for Yoyo.", call: this.yoyoBabyGo },
        { text: "But he was put at the other side of the world, in France." },
        { text: "The flying stars was jealous of their love." },
        { text: "She was alone and wanted to break their love." },
        { text: "After that she goes away, to never be seen again.", call: this.flyingStarOff },
        { text: "They both started their life, with no ideas of their old one." },
        { text: "They we're growing, discovering the world, and soon becoming adult.", call: this.adultGo },
        { text: "But they still had no idea of each other." },
        { text: "But both where feeling like something was missing." },
        { text: "There link wasn't completely broken and was trying to reunite them." },
        { text: "Often they had the same ideas." },
        { text: "We're doing the same things at the same times." },
        { text: "After a while they finally meet online." },
        { text: "They quickly started to talk everyday." },
        { text: "And it didn't take long for them to fall in love for each other." },
        { text: "Soon they we're dreaming to be together.", call: this.adultTogether },
        { text: "But for now it wasn't that easy." },
        { text: "For now that's the end of the story", call: this.stopSpace, src: "/img/yoyoRead.gif" },
        { text: "The rest remains to be lived, by us." },
        { text: "We need to write it together." },

      ],
      dialogColor: "black",
      dialogStep: 0,
      dialongFontSize: 20,
      dialogPause: false,
      haveToKiss: false,
      vitaminsSequence: false,
      vitaminsLevel: 0,

      heartPos: {
        x: 50,
        y: 41,
      },
      toKissPos: {
        x: 44,
        y: 39,
      },
    };
  },
  methods: {
    stopSpace() {
      this.yoyoDuoduoTogether = false;
      this.starsLookEarth = false;
      this.yoyoImg = true;
      this.noBorderYoyoTalk = false;
    },
    adultGo() {
      this.yoyoDuoduoAway = true;
      this.duoduoBaby = false;
      this.yoyoBaby = false;
    },
    adultTogether() {
      this.yoyoDuoduoAway = false;
      this.yoyoDuoduoTogether = true;
    },
    duoduoBabyGo() {
      this.duoduoBaby = true;
      this.starsLeftTop = false;
    },
    yoyoBabyGo() {
      this.yoyoBaby = true;
      this.starsLeftBottom = false;
    },
    flyingStarOn() {
      this.flyingStar = true;
    },
    flyingStarOff() {
      this.flyingStar = false;
    },
    starsLookEarthOn() {
      let data = this.$data;
      this.starsLookEarth = true;
      this.starsTravel = false;
      this.starsLeftTop = true;
      this.starsLeftBottom = true;

      setTimeout(() => {
        data.spaceBackground = true;
      });
    },
    starsTravelOn() {
      let data = this.$data;
      this.spaceBackground = false;
      setTimeout(() => {
        data.starsTravel = true;
      });
    },
    littleLightOn() {
      this.yoyoImg = false;
      this.spaceBackground = true;
    },
    lightOff() {
      this.noBorderYoyoTalk = true;
      let speak = this.speak;
      let data = this.$data;
      document.body.className = "blackBG";

      setTimeout(function () {
        data.dialogPause = false;
        speak();
      }, 1000);
    },
    haveToKissStart() {
      this.haveToKiss = true;
    },
    haveToKissStop() {
      this.haveToKiss = false;
    },
    vitaminsSequenceStart() {
      this.vitaminsSequence = true;
      this.toKissPos = {
        x: 49,
        y: 39,
      };
      this.heartPos = {
        x: 55,
        y: 43,
      };
    },
    spawnHeart() {
      this.heartId++;
      this.heartList[this.heartId] = true;
      this.heartList = { ...this.heartList };

      let lastId = this.heartId;
      let data = this.$data;

      setTimeout(function () {
        delete data.heartList[lastId];
        data.heartList = { ...data.heartList };
      }, 2000);
    },
    kissedVitamins() {
      if (this.vitaminsSequence) {
        this.spawnHeart();
        this.vitaminsLevel += 4;

        if (this.vitaminsLevel < 33) {
          this.yoyoSrc = "/img/yoyoDown.gif";

          this.toKissPos = {
            x: 49,
            y: 39,
          };
          this.heartPos = {
            x: 55,
            y: 43,
          };
        } else if (this.vitaminsLevel < 66) {
          this.yoyoSrc = "/img/yoyoHello.gif";

          this.toKissPos = {
            x: 45,
            y: 39,
          };
          this.heartPos = {
            x: 51,
            y: 42,
          };
        } else if (this.vitaminsLevel < 100) {
          this.yoyoSrc = "/img/yoyoHappy.gif";

          this.toKissPos = {
            x: 45,
            y: 39,
          };
          this.heartPos = {
            x: 51,
            y: 42,
          };
        } else {
          this.vitaminsSequence = false;
          this.dialogPause = false;
          this.speak();
        }
      }
    },
    kissed() {
      if (!this.kissHeart) {
        this.haveToKissStop();
        this.dialogPause = false;
        this.speak();
        this.spawnHeart();
      }
    },
    endStep1() {
      let data = this.$data;
      this.fadeHeart = true;
      this.step1 = false;
      this.step2 = true;

      setTimeout(() => {
        data.displayHeart = false;
      }, 950);
    },
    speak() {
      if (!this.dialogPause) {
        if (this.dialogStep < this.dialog.length - 1) {
          this.dialogStep++;

          if (this.dialog[this.dialogStep].dialogColor != null) {
            this.dialogColor = this.dialog[this.dialogStep].dialogColor;
          }
          if (this.dialog[this.dialogStep].fontSize != null) {
            this.dialongFontSize = this.dialog[this.dialogStep].fontSize;
          }

          if (this.dialog[this.dialogStep].src != null) {
            this.yoyoSrc = this.dialog[this.dialogStep].src;
          }

          if (this.dialog[this.dialogStep].pause != null) {
            this.dialogPause = this.dialog[this.dialogStep].pause;
          }

          if (this.dialog[this.dialogStep].call != null) {
            this.dialog[this.dialogStep].call();
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.flying-star {
  animation: rotateOnSelf 10s  linear infinite;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    transform: translate(-50%, 50%);
    transform-origin: 0% 0;
}

@keyframes rotateOnSelf {
  0% {
    transform: rotate(0deg);

  }

  100% {
        transform: rotate(360deg);


  }
}

.stars-top-left {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 200px;
  transform: translate(-50%, -50%);
}

.stars-bottom-left {
  position: absolute;
  bottom: 25%;
  left: 25%;
  width: 200px;
  transform: translate(-50%, 50%);
}

.earth-right {
  z-index: -1;
  position: absolute;
  bottom: 50%;
  right: 50;
  width: 1000px;
  transform: translate(-50%, 50%);
}
.stars-top {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, -50%);
}
.stars-bottom {
  position: absolute;
  bottom: 25%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, 50%);
}
[class="heart"] {
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 0;
  opacity: 1;

  color: #920d0d;
  will-change: font-size;
  animation: heart 2s forwards;
}

@keyframes heart {
  0% {
    font-size: 0px;
    opacity: 1;
  }

  100% {
    font-size: 200px;
    opacity: 0;
  }
}
</style>
<style scoped>
#vitaminsLabel {
  position: absolute;
  bottom: -15%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: #920d0d;
}
#vitaminsBar {
  height: 30px;
  width: 300px;
  padding: 5px;
  position: absolute;
  bottom: -76px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d8d8d8;
}

#emptyBar {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 290px;
  height: 20px;
  background-color: #b4b4b4;
}

#fullBar {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  height: 20px;
  background-color: #920d0d;
}
.have-to-kiss {
  pointer-events: auto;
  cursor: pointer;
  width: 50px;
  height: 21px;
  position: absolute;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid black;
}
.instruction {
  position: relative;
  top: -137px;
  left: -212px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.typing {
  color: white;
  position: absolute;
  top: 50%;
  z-index: 1000;
  transform: translateY(-50%);
}

.yoyoTalk {
  pointer-events: auto;
  cursor: pointer;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 52px;
  border-bottom: solid 2px black;
  transform: translate(-50%, -100%);
  transform-origin: 50% 0%;
  width: 300px;
}

img {
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.yoyoTalkNoBorder {
  pointer-events: auto;
    cursor: pointer;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}

.yoyoTalkNoBorder > p {
  margin: 0;
text-shadow: 2px 2px black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color: black;
  font-size: 18px;
}

.yoyoTalk > p {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color: black;
  font-size: 18px;
}
.yoyoTalk::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@keyframes giant {
  from {
    transform: scale(250);
  }
  to {
    transform: scale(1);
  }
}
.st0 {
  fill: #920d0d;
}
svg {
  transform: scale(250);
  z-index: 1000;
  position: absolute;
  top: 55.5%;
  left: 46%;
}

.fadeGrow-enter-active,
.fadeGrow-leave-active {
  transition: transform 0.5s;
}
.fadeGrow-enter,
.fadeGrow-leave-to {
  opacity: 0;
  transform: scale(0);
}

.giant {
  animation-name: giant;
  animation-duration: 1s;
}

.background {
  width: 100%;
  height: 100%;
  background-color: #920d0d;
}

#step-3 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>