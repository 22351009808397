import Vue from 'vue';
import App from './App.vue';
import VueTypedJs from 'vue-typed-js'
import Particles from "particles.vue";

Vue.config.productionTip = true;
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueTypedJs)

Vue.use(Particles);

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
