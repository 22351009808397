import Vue from 'vue'
import VueRouter from 'vue-router'
import Step1 from "../views/Step1"
import Step2 from "../views/Step2"
import Step3 from "../views/Step3"
import dani from "../views/dani"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Step1',
    component: Step1
  },
  {
    path: '/2/:name',
    name: 'Step2',
    component: Step2
  },
  {
    path: '/dani/:name',
    name: 'dani',
    component: dani
  },
  {
    path: '/3/:name',
    name: 'Step3',
    component: Step3
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
